<script>
import {
    ArrowUpIcon,
    ArrowRightIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

/**
 * Page-privacy component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon
    }
}
</script>

<template>
<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="title">Refund Policies</h4>
                        <ul class="list-unstyled mt-4">
                            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Last Revised :</span> 23th Sep, 2020</li>
                        </ul>
                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-white rounded shadow mb-0">
                                    <li class="breadcrumb-item"><a href="/terms">Terms</a></li>
                                    <li class="breadcrumb-item"><a href="/privacy">Privacy</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Refund</li>
                                    <li class="breadcrumb-item"><a href="/disclosure">Disclosure</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->

    <!-- Start Privacy -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="card shadow rounded border-0">
                        <div class="card-body">
                           





<h5><strong>At Karukaj DIGITAL we take great pride in delighting our customers.</strong></h5>

<p>
<br>
</p>
<h4>Website Design and Development</h4>
The deposit paid to Karukaj DIGITAL covers the cost of design work carried out. It is not possible to refund a deposit after thirty (30) days or once Karukaj DIGITAL has sent the first design to the Client. Karukaj DIGITAL reserve the right to decide whether a refund is applicable if requested by the Client for any reason. A refund is not guaranteed by Karukaj DIGITAL, but possible providing Karukaj DIGITAL decides upon this option. If paid by credit card, refunds will be issued to the original credit card provided at the time of purchase. If your account has been canceled by us due to a breach of the terms and conditions on your part, you will not be eligible for a refund. Phone requests will not constitute acceptance of any cancellation.
<p>&nbsp;</p>
<h4>Web Hosting Accounts</h4>
All new web hosting accounts are covered by a 30-day money back guarantee. If you become dissatisfied with our services at any point within the first 30 days of holding an active account, we will issue a 100% refund of your money unless otherwise specified at, or prior to, the time of purchase.
<p>&nbsp;</p>
<h4>Web Hosting Renewals</h4>
Your web hosting account will renew on a monthly basis. Refunds for web hosting account renewals will be granted to customers who request a cancellation of their service, in writing, no later than 5 days before the renewal date. No exceptions will be made to this policy.
<p>&nbsp;</p>
<h4>Domain Names</h4>
All domain names sales are made final at the time of purchase. You are the sole owner of any domain names registered through our services upon payment in full. Should you decide to leave our service for another provider, domain names are transferable and therefore not subject to refunds.
<p>&nbsp;</p>
<h4>Changes to registered domain names</h4>
(incorrect spelling, etc..) cannot be made once the domain has been purchased with a domain registry. It is very important that you verify the spelling of your domain name and it is recommended that you have someone else read it as well to ensure it is exactly as expected.
<p>&nbsp;</p>
<h4>Domain Name Renewals</h4>
Domain Name renewals are billed automatically on the schedule agreed to in the contract. They will renew automatically until you write in and cancel the renewal of you domain name. Keep in mind that domain names are on a minimum of an annual plan.
<br><br>
                            <a href="javascript:void(0)" class="btn btn-primary">Print</a>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->






<br><br><br>
      
        <div class="container mt-4 mt-lg-0">
            <div class="row align-items-center mb-4 pb-4">
                <div class="col-md-8">
                    <div class="section-title text-center text-md-left">
                        <h4 class="mb-4">Need help? We're always here for you</h4>
                        <p class="text-muted mb-0 para-desc">Start working with <span class="text-primary font-weight-bold">Karukaj</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 mt-4 mt-sm-0">
                    <div class="text-center text-md-right">


                        <router-link  class="btn btn-soft-primary font-weight-bold mt-4 text-center" to="/disclosure"> Read FTC Disclosure <arrow-right-icon class="fea icon-sm"></arrow-right-icon> </router-link> 




                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

        </div>
        <!--end container-->






    </section>
    <!--end section-->
    <!-- End Privacy -->
    <!--end section-->



    <section
      class="section bg-white pt-0"
      :style="{
        background:
          'url(' +
          require('../../public/images/real/build.png') +
          ') bottom no-repeat',
      }"
    >
  
      
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
       
              <div class="mt-4 pt-2">
                <br><br>


  
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!--end container-->
    </section>
    <!--end section-->














    <!-- End -->
<div class="position-relative">
        <div class="shape overflow-hidden text-footer">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--end section-->

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
